// Website Variables and Colours

$container-xl: 1400px !default;

$base-background-color: #E8EEEE;
$base-font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$base-heading-font-family: 'PlayfairDisplay-Regular', 'Times New Roman', serif;
$base-h1-font-family: $base-font-family;
$base-h1-color: #3B8686;
$base-h1-font-size: 50;
$base-h1-line-height: 50 !default;

$base-h2-color: #565C5C;
$base-h2-font-size: 26;
$base-h2-line-height: 30;

// $base-h1-color: #3B8686;
// $base-h1-font-size: 80;

// $base-h2-color: #565C5C;
// $base-h2-font-size: 36;
// $base-h2-line-height: 40;