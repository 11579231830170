main {
  h1 {
    font-family: $base-h1-font-family;
    text-align: center;

    @include breakpoint(m) {
      text-align: left;
      font-size: 45px;
      line-height: 45px;
    }

    @include breakpoint(l) {
      font-size: 65px;
      line-height: 65px;
    }

    @include breakpoint(xl) {
      font-size: 75px;
      line-height: 45px;
    }
  }

  h2 {
    text-align: center;

    @include breakpoint(m) {
      text-align: left;
      font-size: 20px;
    }

    @include breakpoint(l) {
      font-size: 30px;
      line-height: 40px;
    }

    @include breakpoint(xl) {
      font-size: 30px;
      line-height: 50px;
    }
  }

  section {
    padding: 100px 0;
  }

  img.brand {
    max-height: 45px;
  }

  img.android-beta-label {
    @include box-shadow(0px, 0px, 0px, #000000);

    left: 101px;
    top: -9px;

    @include breakpoint(m) {
      left: 267px;
      top: -10px;
    }
  }

  .embedhunt-container {
    @include breakpoint(l) {
      width: 700px;
      margin: 0 auto;
    }
  }

  .testimonial {
    .text {
      font-family: $base-heading-font-family;
      font-size: 22px;
      line-height: 30px;
      color: #242526;
    }

    img.avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    div.person {
      font-family: Montserrat;
      letter-spacing: 0.7px;
      text-transform: uppercase;
      color: #242526;
      font-size: 12px;

      a {
        text-decoration: none;
      }

      .name {
        font-weight: bold;
      }

      .company {
        font-size: 10px;
        line-height: 14px;
        color: #8B8B8B;
      }
    }
  }

  .footer {
    font-family: Montserrat;
    font-size: 10px;
    color: #ACACAC;
    letter-spacing: 0.35px;
    text-align: center;

    a {
      color: #ACACAC;
      text-decoration: none;
    }

    @include breakpoint(m) {
      text-align: left;

      li.pad-left {
        padding-left: 20px;
      }
    }

    .apple-legalease {
      color: #ddd;
    }
  }
}
